/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Devstroupe's base styles.
/* ----------------------------------------------------------------------------------------------------- */
.card-dialog {
    .mat-mdc-dialog-container .mdc-dialog__surface {
        padding: 0 !important;
    }
}

.card-dialog-flex-auto {
    .mat-mdc-dialog-container .mdc-dialog__surface {
        padding: 0 !important;
        overflow: hidden !important;
    }
}

.notification-dialog {
    .mat-mdc-dialog-container {
        .mat-mdc-dialog-surface {
            overflow: inherit;
        }
    }
}

.light .mat-mdc-header-row .mat-mdc-table-sticky,
.mat-mdc-header-row .mat-mdc-table-sticky,
.dark .mat-mdc-header-row .mat-mdc-table-sticky,
.mat-mdc-table-sticky,
.light .mat-mdc-cell,
.dark .mat-mdc-cell {
    padding: 8px !important;
    // text-align: center !important;
}

.light th.mat-mdc-table-sticky:first-of-type,
.dark th.mat-mdc-table-sticky:first-of-type,
.light .mat-mdc-cell:first-of-type,
.dark .mat-mdc-cell:first-of-type {
    padding-left: 1.5rem !important;
}

.mat-mdc-header-row .mat-mdc-table-sticky,
.mat-mdc-header-row {
    // @apply dark:bg-gray-700 bg-gray-100 #{!important};
}

.dark .striped {
    .mat-mdc-row:nth-child(even) {
        @apply bg-gray-700 #{!important};
    }

    .mat-mdc-row:nth-child(even) .mat-mdc-table-sticky {
        @apply bg-gray-700 #{!important};
    }
}

.light .striped {
    .mat-mdc-row:nth-child(odd):not(.item-problem) {
        @apply bg-gray-100 #{!important}
    }

    .mat-mdc-row:nth-child(odd) .mat-mdc-table-sticky {
        @apply bg-gray-100 #{!important}
    }
}

// ===========table inside expansion panel===============
.mat-expansion-panel {

    .mat-mdc-header-row .mat-mdc-table-sticky,
    .mat-mdc-header-row {
        @apply dark:bg-gray-800 bg-gray-200 #{!important};
    }

    .striped {
        .mat-mdc-row:nth-child(even) {
            @apply bg-gray-200 dark:bg-gray-800 #{!important};
        }

        .mat-mdc-row:nth-child(even) .mat-mdc-table-sticky {
            @apply bg-gray-200 dark:bg-gray-800 #{!important};
        }

        .mat-mdc-row:nth-child(odd) {
            @apply dark:bg-gray-700 #{!important};
        }

        .mat-mdc-row:nth-child(odd) .mat-mdc-table-sticky {
            @apply dark:bg-gray-700 #{!important};
        }
    }

    // .striped {
    //    .mat-mdc-row:nth-child(even) {
    //        @apply bg-gray-200 #{!important}
    //     }
    //    .mat-mdc-row:nth-child(even) .mat-mdc-table-sticky {
    //         @apply bg-gray-200 #{!important}
    //     }
    // }

    .mat-expansion-panel {
        @apply dark:bg-gray-800 bg-gray-200 #{!important};
    }
}

.light .mat-expansion-panel table {
    @apply border-b-2 border-gray-200 #{!important}
}

.light .inner-accordion {
    .mat-expansion-panel table {
        @apply border-b-2 border-white #{!important}
    }
}

// =========================================================

::-webkit-scrollbar-corner {
    background-color: transparent;
}

.dark .mat-mdc-table-sticky:has(th[title=actions-th]),
.light .mat-mdc-table-sticky:has(th[title=actions-th]) {
    @apply text-left #{'!important'};
}

.dark table {
    // border-bottom: 2px solid #1e293b!important;
}

.light table {
    // border-bottom: 2px solid #fff !important;
}

// body.dark{
//     @apply bg-gray-900 #{'!important'};
// }

// body.light{
//     @apply bg-slate-100 #{'!important'};
// }

// .bg-card{
//     @apply dark:bg-dark-400 bg-gray-50 #{'!important'};
// }

.mat-mdc-dialog-container .mdc-dialog__surface {
    @apply bg-card #{'!important'};
}

.mat-mdc-select-panel {
    @apply bg-card #{'!important'};
}

.mat-form-field.mat-form-field-appearance-fill.mat-focused .mat-form-field-wrapper .mat-form-field-flex {
    @apply dark:bg-gray-700 #{'!important'};
}


#evolutions-table.mat-mdc-table {

    .mdc-data-table__header-row,
    .mdc-data-table__row {
        @apply h-6 #{'!important'};
    }

    .semana-subtd {
        max-width: 40px !important;
        //width: 40px !important;
        min-width: 40px !important;
    }
}

#evolutions-table2.mat-mdc-table {

    .mdc-data-table__header-row,
    .mdc-data-table__row {
        @apply h-6 #{'!important'};
    }

    .semana-subtd td:nth-child(4n + 1) {
        border-left: 1px solid !important;
        /* Borda esquerda */
        border-top: 1px solid !important;
        /* Borda superior */
        border-bottom: 1px solid !important;
        /* Borda inferior */
        border-right: 0px solid !important;
        /* Borda direita */
    }

    /* Estilos de borda para as células do meio de cada grupo de 4 células */
    .semana-subtd td:nth-child(4n + 2),
    .semana-subtd td:nth-child(4n + 3) {
        border-left: 0px solid !important;
        /* Borda esquerda */
        border-top: 1px solid !important;
        /* Borda superior */
        border-bottom: 1px solid !important;
        /* Borda inferior */
        border-right: 0px solid !important;
        /* Borda direita */
    }

    /* Estilos de borda para a última célula de cada grupo de 4 células */
    .semana-subtd td:nth-child(4n) {
        border-left: 0px solid !important;
        /* Borda esquerda */
        border-top: 1px solid !important;
        /* Borda superior */
        border-bottom: 1px solid !important;
        /* Borda inferior */
        border-right: 1px solid !important;
        /* Borda direita */
    }

    .semana-subtd {
        // border: none !important;
        max-width: 40px !important;
        //width: 40px !important;
        min-width: 40px !important;
    }
}

.mat-mdc-optgroup-label {
    @apply capitalize font-bold #{'!important'};
}

th,
td,
button,
input,
select,
textarea,
mat-label,
.mdc-label,
.mdc-list-item span,
.mdc-tab span {
    @apply text-md #{'!important'};
}


//temporário
.light .evolution {

    th.mat-mdc-header-cell,
    td.mat-mdc-cell {
        text-align: center;
        border-color: rgb(73 67 67 / 12%) !important;
        padding: 5px !important;
        max-width: 50px !important;
    }

    @media only screen and (max-width: 600px) {

        th.mat-mdc-header-cell,
        td.mat-mdc-cell {
            text-align: center;
            border-color: rgb(73 67 67 / 12%) !important;
            padding: 2px !important;
            max-width: 150px !important;
        }

        .mat-mdc-table-sticky {
            text-align: center;
            border-color: rgb(73 67 67 / 12%) !important;
            padding: 2px !important;
        }
    }

    th.mat-column-quantidade,
    td.mat-column-quantidade {
        border-right-width: 3px !important;
        @apply border-r-stone-400 #{!important};

    }

}

.dark .evolution {

    th.mat-mdc-header-cell,
    td.mat-mdc-cell {
        text-align: center;
        border-color: rgba(255, 255, 255, 0.12) !important;
        padding: 5px !important;
        max-width: 50px !important;


    }

    @media only screen and (max-width: 600px) {

        th.mat-mdc-header-cell,
        td.mat-mdc-cell {
            text-align: center;
            border-color: rgba(255, 255, 255, 0.12) !important;
            padding: 2px !important;
            max-width: 150px !important;
        }

        .mat-mdc-table-sticky {
            text-align: center;
            border-color: rgba(255, 255, 255, 0.12) !important;
            padding: 2px !important;
        }

    }

    th.mat-column-quantidade,
    td.mat-column-quantidade {
        border-right-width: 3px !important;
        @apply border-r-stone-400 #{!important};

    }

    // .mat-table-sticky-border-elem-left.col-qtd {
    //     border-right-width: 2px !important;
    //     @apply border-r-red-500 #{!important};
    // }

    // th.mat-mdc-header-cell.col-qtd, td.mat-mdc-cell.col-qtd {
    //     border-right-width: 2px !important;
    //     @apply border-r-red-500 #{!important};
    //     // border-color: rgb(220 41 62) !important;
    // }


}

.evolution {
    table {
        border-collapse: separate !important;
        /* Don't collapse */
        padding: 0px !important;

        // border-spacing: 0 !important;
    }

    .mat-mdc-table {
        table-layout: auto !important;
        white-space: nowrap !important;
        padding: 0px !important;
    }

    @media only screen and (max-width: 600px) {
        .mat-mdc-table {
            table-layout: auto !important;
            white-space: normal !important;
        }

        .td-service-name {
            max-width: 150px !important;
            width: 150px !important;
            min-width: 150px !important;
            white-space: normal !important;
            text-align: start;
        }
    }

    th.mat-mdc-header-cell,
    td.mat-mdc-cell {
        border: 0.5px solid !important;
        border-bottom: 0.5px solid !important;
        border-collapse: separate !important;
        /* Don't collapse */
        border-spacing: 0 !important;
    }

    .mat-mdc-table .mdc-data-table__row:not(.mdc-data-table__row--selected):hover {
        background-color: inherit !important;
    }

    th.mat-mdc-header-cell {
        @apply dark:bg-gray-700 bg-gray-100 #{!important};
    }

    .mat-mdc-table-sticky {
        text-align: center;
        border: 0.5px solid !important;
        padding: 5px !important;
        background-color: inherit !important;
    }

    .td-service-name {
        max-width: 350px !important;
        width: 350px !important;
        min-width: 350px !important;
        white-space: normal !important;
        text-align: start;
    }

    .no_evolutions {

        td:not(.mat-column-quantidade),
        td.mat-column-quantidade {
            border-top-color: rgb(0, 98, 178) !important;
            border-bottom-color: rgb(0, 98, 178) !important;
        }
    }
}

.light .evolution2 {

    th.mat-mdc-header-cell,
    td.mat-mdc-cell {
        text-align: center;
        border-color: rgb(73 67 67 / 12%) !important;
        padding: 5px !important;
        max-width: 50px !important;
    }

    @media only screen and (max-width: 600px) {

        th.mat-mdc-header-cell,
        td.mat-mdc-cell {
            text-align: center;
            border-color: rgb(73 67 67 / 12%) !important;
            padding: 2px !important;
            max-width: 150px !important;
        }

        .mat-mdc-table-sticky {
            text-align: center;
            border-color: rgb(73 67 67 / 12%) !important;
            padding: 2px !important;
        }
    }

    th.mat-column-quantidade,
    td.mat-column-quantidade {
        border-right-width: 3px !important;
        @apply border-r-stone-400 #{!important};

    }

}

.dark .evolution2 {

    th.mat-mdc-header-cell,
    td.mat-mdc-cell {
        text-align: center;
        border-color: rgba(255, 255, 255, 0.12) !important;
        padding: 5px !important;
        max-width: 350px !important;


    }

    @media only screen and (max-width: 600px) {

        th.mat-mdc-header-cell,
        td.mat-mdc-cell {
            text-align: center;
            border-color: rgba(255, 255, 255, 0.12) !important;
            padding: 2px !important;
            max-width: 150px !important;
        }

        .mat-mdc-table-sticky {
            text-align: center;
            border-color: rgba(255, 255, 255, 0.12) !important;
            padding: 2px !important;
        }

    }

    th.mat-column-quantidade,
    td.mat-column-quantidade {
        border-right-width: 3px !important;
        @apply border-r-stone-400 #{!important};

    }

    // .mat-table-sticky-border-elem-left.col-qtd {
    //     border-right-width: 2px !important;
    //     @apply border-r-red-500 #{!important};
    // }

    // th.mat-mdc-header-cell.col-qtd, td.mat-mdc-cell.col-qtd {
    //     border-right-width: 2px !important;
    //     @apply border-r-red-500 #{!important};
    //     // border-color: rgb(220 41 62) !important;
    // }


}

.evolution2 {
    table {
        border-collapse: separate !important;
        /* Don't collapse */
        padding: 0px !important;

        // border-spacing: 0 !important;
    }

    .mat-mdc-table {
        table-layout: auto !important;
        white-space: nowrap !important;
        padding: 0px !important;
    }

    // .mdc-data-table__content{
    //     transform: scaleY(-1) !important;
    // }

    @media only screen and (max-width: 600px) {
        .mat-mdc-table {
            table-layout: auto !important;
            white-space: normal !important;
        }

        .td-service-name {
            max-width: 80px !important;
            width: 80px !important;
            min-width: 80px !important;
            white-space: normal !important;
            text-align: start;
        }
    }

    th.mat-mdc-header-cell,
    td.mat-mdc-cell {
        border: 0.5px solid !important;
        border-bottom: 0.5px solid !important;
        border-collapse: separate !important;
        /* Don't collapse */
        border-spacing: 0 !important;
    }

    .mat-mdc-table .mdc-data-table__row:not(.mdc-data-table__row--selected):hover {
        background-color: inherit !important;
    }

    th.mat-mdc-header-cell {
        @apply dark:bg-gray-700 bg-gray-100 #{!important};
    }

    .mat-mdc-table-sticky {
        text-align: center;
        border: 0.5px solid !important;
        padding: 5px !important;
        background-color: inherit !important;
    }

    .td-service-name {
        max-width: 100px !important;
        width: 100px !important;
        min-width: 100px !important;
        white-space: normal !important;
        text-align: start;
    }
}

.evolution-edit {
    .mat-mdc-table {
        table-layout: auto !important;
        white-space: nowrap !important;
        padding: 0px !important;
    }


    @media only screen and (max-width: 600px) {
        .mat-mdc-table {
            table-layout: auto !important;
            white-space: normal !important;
        }

        .td-service-name {
            max-width: 150px !important;
            width: 150px !important;
            min-width: 150px !important;
            white-space: normal !important;
            text-align: start;
        }
    }

    .td-service-name {
        max-width: 350px !important;
        width: 350px !important;
        min-width: 350px !important;
        white-space: normal !important;
        text-align: start;
    }
}

.evolution,
.evolution2 {

    .mat-mdc-table .mdc-data-table__row,
    .mat-mdc-table .mdc-data-table__header-row {
        height: 25px !important;
        min-height: 25px !important;
        max-height: 25px !important;

        td,
        th {
            padding-bottom: 0 !important;
            padding-top: 0 !important;
        }
    }
}

@media print {
    .print-modal {
        width: 100% !important;
        max-width: 100% !important;
        display: block !important;

        mat-dialog-container {
            border-radius: 0 !important;
            box-shadow: none !important;
            background-color: transparent !important;
        }
    }
}

.print-modal {

    overflow: auto !important;
    // max-width: 90vw;
    width: 100% !important;
    display: none;

    .mat-mdc-dialog-container .mdc-dialog__surface {
        @apply bg-white #{'!important'};
    }

}


.truncate-text {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
}

.qrcode-dialog {
    .mat-mdc-dialog-container .mdc-dialog__surface {
        overflow: hidden !important;
        padding: 0 !important;
    }
}


.max-width-unset .mdc-tooltip__surface {
    max-width: unset !important;
    max-height: unset !important;
}

.mat-mdc-icon-button.no-rounded {
    @apply rounded-2xl #{'!important'};
}

.search-input.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper {
    box-shadow: none !important;
    border-radius: 10px !important;
}

.mat-sort-header-content {
    white-space: nowrap !important;
}

.mat-mdc-paginator .mat-mdc-paginator-container {
    padding: 2px 16px !important;
}

.mat-mdc-paginator .mat-mdc-paginator-container .mat-mdc-paginator-page-size {
    margin: 0 !important;
}

.mat-accordion .mat-expansion-panel.no-hover:hover {
    box-shadow: 0 0 #000000, 0 0 #000000, 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1) !important;
}

.card-shadow {
    box-shadow: 0 0 #000000, 0 0 #000000, 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1) !important;
}

@media screen and (max-width: 600px) {
    .cdk-overlay-pane {
        max-width: 100vw !important;
        width: 100vw !important;
        min-width: 100vw !important;
    }

    .card-dialog-flex-auto {
        max-width: 100dvw !important;
        width: 100dvw !important;
        min-width: 100dvw !important;
        max-height: 100dvh !important;
        height: 100dvh !important;
        min-height: 100dvh !important;

    }
}

.item-problem {
    @apply bg-red-300 #{'!important'}
}


.mediaLigthbox {
    height: 100% !important;
    width: 100% !important;
    max-width: 100vw !important;
    max-height: 100vh !important;
    background-color: rgb(20 20 20 / 40%) !important;
    backdrop-filter: blur(5px);
    pointer-events: none;
    display: flex;
    justify-content: center;
    align-items: center;

    lightbox {
        height: inherit !important;
        box-shadow: none !important;
        max-height: 100vh !important;

        gallery {
            background-color: transparent !important;
            padding: 2.5rem 0;

            @media screen and (max-width:724px) {
                gallery-item {
                    padding: 0 2.4rem;
                }
            }

            .g-image-item {
                object-fit: contain !important;
                display: none !important;
            }

            gallery-thumb {
                pointer-events: all !important;

                .g-image-item {
                    display: flex !important;
                }
            }

            gallery-nav i {
                pointer-events: all !important;
            }

            .g-btn-close {
                pointer-events: all !important;
            }

            .g-template.g-item-template {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
            }
        }
    }
}

.nav-button.mat-mdc-unelevated-button {
    height: 32px !important;
    min-height: 32px !important;
    max-height: 32px !important;
    padding: 0 14px !important;
}

.cdk-drag-preview {
    box-sizing: border-box;
    border-radius: 4px;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
        0 8px 10px 1px rgba(0, 0, 0, 0.14),
        0 3px 14px 2px rgba(0, 0, 0, 0.12);
    @apply bg-slate-100
}

.cdk-drag-placeholder {
    opacity: 0;
    // background: #ccc;
    // border: dotted 3px #999;
    // min-height: 60px;
    // transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drag-animating {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drop-list-dragging .cdk-drag:not(.cdk-drag-placeholder) {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.theme-default .light .mdc-text-field--disabled .mdc-text-field__input,
.theme-default.light .mdc-text-field--disabled .mdc-text-field__input {
    color: black !important;
}


// Alterando estilo padrão do scrool vertical das páginas:

/* Estilos personalizados para a barra de rolagem vertical */
::-webkit-scrollbar {
    width: 16px !important;
    /* Largura da barra de rolagem vertical */
}

::-webkit-scrollbar-track {
    background: #f1f1f1 !important;
    /* Cor de fundo da barra de rolagem */
}

::-webkit-scrollbar-thumb {
    background-color: #cfcfcf !important;
    /* Cor da "thumb" da barra de rolagem */
    border-radius: 10px !important;
    /* Borda arredondada */
    border: 3px solid #f1f1f1 !important;
    /* Espaço ao redor da thumb */
}

::-webkit-scrollbar-thumb:hover {
    background: #555 !important;
    /* Cor ao passar o mouse por cima */
}

/* Não alterar a barra de rolagem horizontal */
::-webkit-scrollbar:horizontal {
    height: 8px !important;
    /* Altura da barra de rolagem horizontal */
}

.progress-bar-bg {
    @apply bg-gray-200 h-2 rounded-full relative;
}

.progress-bar-fill {
    @apply h-2 rounded-full;
}

layout-ecommerce {
    // position: relative;
    // display: flex;
    // flex: 1 1 auto;
    // width: 100%;
}


swiper-container::part(button-prev) {
    color: rgb(250 204 21 / 1);
}

swiper-container::part(button-next) {
    color: rgb(250 204 21 / 1);
}

swiper-container::part(bullet-active) {
    background-color: rgb(250 204 21 / 1);
}

ckeditor {
    // display: block;
    // height: 300px;

    .ck-content {
        // display: block;
        min-height: 200px;

        // max-height: 500px;
    }
}